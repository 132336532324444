import React from "react"
import { Link } from "gatsby"

const Layout = ({ location, title, children }) => {
  const rootPath = `${__PATH_PREFIX__}/`
  const isRootPath = location.pathname === rootPath
  let header

header = (
  <h1 className="main-heading">
    <Link to="/">
      Q for Query
    </Link>
  </h1>
)

  return (
    <div className="global-wrapper" data-is-root-path={isRootPath}>
      <header className="global-header">{header}</header>
      <main>{children}</main>
      <footer>
        © {new Date().getFullYear()} Chris Reynolds
      </footer>
    </div>
  )
}

export default Layout
